<template>
	<div class="container-fluid">
		<div class="row">
			<div class="col-lg-12">
				<div class="d-flex flex-wrap flex-wrap align-items-center justify-content-between mb-4">
                    <div>
                        <h4 class="mb-3">{{title}}</h4>
                        <p class="mb-0">{{desc1}}<br> {{desc2}}<br>{{desc3}}</p>
                        <!-- <p v-b-modal.modal-3 variant="link" class="mb-0"><u>판매량 모니터링 상품 추가 방법</u></p>
                        <div v-if="showspinner"><img style="max-width:10%;" :src="require('../../../assets/images/small/loading.gif')">{{loadingtext}}</div> 
                            <b-modal id="modal-3" size="xl" title="쿠팡 모니터링 상품 추가 방법" ok-only>
                                <p><a href="https://chrome.google.com/webstore/detail/couplus/bogokmehpkdjafnlhjhinfgmeljdgmgh?hl=ko&authuser=0" target="_blank"><u>구글웹스토어</u></a>에 접속해서 "COUPLUS"를 다운받은 후 쿠플러스에 로그인한 다음 <b style="color:red"><u>"쿠팡 상품페이지"에서</u></b> "링크수집(판매량)" 버튼을 클릭해주세요.<br> 한번 추가된 상품은 매일 1회 자동으로 수집됩니다.</p>
                                <img  style="max-width:100%;" :src="require('../../../assets/images/small/모니터링추가.png')">
                            </b-modal> -->
					</div>
					<!-- <b-button variant="primary pl-5 pr-5" class="mt-2 mr-1" @click="collecttr">{{linktext}}</b-button> -->
                </div>
			</div>
			
			<div class="col-lg-12" v-if="showlist">
				<b-button variant="outline-primary" class="mb-1 mr-2" @click="showts">
				트렌드세터
				</b-button>
				<b-button variant="outline-secondary" class="mb-1 mr-2" @click="showtf">
				트렌드팔로워
				</b-button>    
			</div>

			<!-- <div class="col-lg-6 mt-3" v-if="showlist">
				<b-button variant="outline-success pl-5 pr-5" class="mt-2 mr-1 mb-2" v-b-toggle.my-collapse>판매량 보정</b-button>
				<b-collapse id="my-collapse">
					<b-card title="">
						보정치 적용 시 마이너스 판매량을 0으로 바꾸어 줍니다. (마이너스 임계치가 -2일경우 -2이하의 판매량은 모두 0으로 적용)
						<br>
						<br>
						[마이너스 임계치]
						<div class="form-group d-inline-block d-flex justify-content-between">
							<input type="number" class="form-control" placeholder="마이너스 임계치" v-model="minuslimit">

						</div>
						<div class="custom-control custom-switch custom-control-inline">
								<input type="checkbox" class="custom-control-input" id="customSwitch2" v-model="applyminuslimit" @change="applycorrect">
								<label class="custom-control-label" for="customSwitch2" v-b-tooltip.bottom title="전체 페이지에서 검색하며, 여러개가 검색될 경우 페이지랭킹이 여러게로 나타납니다.">보정치 적용</label>
							</div>						
					</b-card>
				</b-collapse>      
			</div> -->

			<div class="col-lg-12 mt-3" v-if="showlist" style="font-size:80%">
				<div class="table-responsive rounded mb-3">
					<tableList>
						<template v-slot:header>
							<tr class="ligth ligth-data text-center">
                                <th @click="checkalllists = !checkalllists">
                                    <div class="checkbox d-inline-block">
                                        <input type="checkbox" class="checkbox-input" id="checkbox2" v-model="checkalllists" @change="checkalllist">
                                        <label for="checkbox2" class="mb-0"></label>
                                    </div>
                                </th>
								<th style="font-size:100%">타겟키워드</th>
								<th style="font-size:100%">상품정보</th>
								
								<th class="text-center" style="font-size:100%">상품매칭</th>
								<th style="font-size:100%">매입가(원)</th>
								<th style="font-size:100%">예상월수익(원)</th>
								<th style="font-size:100%">예상마진(원)<br>마진율(%)</th>
								<th style="font-size:100%">판매가(원)</th>
								<th style="font-size:100%">기준수수료(%)</th>
								<th style="font-size:100%">예상월판매량(개)</th>
								<th style="font-size:100%">예상월매출(원)</th>
								<th style="font-size:100%">리뷰수</th>
								<th style="font-size:100%">리뷰평점</th>
								
								<th style="font-size:100%">로켓뱃지</th>
								
							</tr>
						</template>
						<template v-slot:body>
							<tr v-for="(saleslist,index) in saleslists" :key="index" class="text-center">
								<td class="text-center" @click="saleslist.checked = !saleslist.checked">
                                    <div class="checkbox d-inline-block">
                                        <input type="checkbox" class="checkbox-input" id="checkbox2" v-model="saleslist.checked">
                                        <label for="checkbox2" class="mb-0"></label>
                                    </div>
                                </td>
								<td style="overflow:hidden;text-overflow: ellipsis;">{{saleslist.타겟키워드}}</td>
								<td style="min-width:250px;overflow:hidden;text-overflow: ellipsis;">
									<div class="d-flex align-items-center">
                                        <img :src="saleslist.썸네일" class="img-fluid rounded avatar-100 mr-3" alt="image" @click="openWebsite(saleslist.판매링크)">
                                        <div>
                                        {{saleslist.판매상품명}}
                                            <p class="mb-0"><small>ProductId: {{saleslist.productId}}</small></p>
                                        </div>
                                    </div>
								</td>
								<td style="min-width:250px;overflow:hidden;text-overflow:ellipsis;">
									<div class="d-flex justify-content-between">
										<div class="d-flex justify-content-center">
											<img :src="saleslist.상품매칭[saleslist.currentindex].pic_url" class="img-fluid rounded avatar-100" alt="image" @click="linktopurchase(saleslist.상품매칭[saleslist.currentindex])">
											<div class="d-flex flex-column">
												<b-badge variant="white" v-b-modal="modalIDca(index)"><b-icon icon="search" scale="2" variant="dark"></b-icon></b-badge>
												<b-badge variant="white" @click="previmg(saleslist,true)"><b-icon icon="arrow-left-square" scale="2" variant="dark"></b-icon></b-badge>
												<b-badge variant="white" @click="nextimg(saleslist,true)"><b-icon icon="arrow-right-square" scale="2" variant="dark"></b-icon></b-badge>
											</div>
										</div>
										<div class="d-flex flex-column mt-1 w-100">
											<div class="d-flex justify-content-between align-items-center w-100 text-center">
												CNY <input type="number" class="form-control ml-1 text-center" placeholder="구매가(CNY)" v-model="saleslist.구매가" @change="changeCost(saleslist)">
											</div>
											<div class="d-flex justify-content-between align-items-center w-100 text-center">
												&nbsp;&nbsp;&nbsp;&nbsp;x&nbsp;&nbsp;&nbsp;&nbsp; <input type="number" class="form-control ml-1 text-center" placeholder="매입가율" v-model="saleslist.매입가율" @change="changeCost(saleslist)">
											</div>
										</div>
									</div>

									<b-modal :id="'modalca'+index" size="sm" scrollable title="상품매칭" ok-title="적용" cancel-title="취소" class="detail-modal" style="font-size:80%" @ok="applyCurrentObj(saleslist)">
										<div class="d-flex justify-content-center h-100 w-100">
											<div class="h-100 w-50">
												<b-img class="mh-100 mw-100" rounded :src="saleslist.썸네일" alt="Responsive image"></b-img>
											</div>
											<div class="h-100 w-50 d-flex justify-content-center align-items-center">
												<button type="button" class="btn btn-outline-dark mr-2" @click="previmg(saleslist,false)"><b>&lt;</b></button>
												<div id="carouselExampleControls" class="h-100 carousel slide mr-2" data-bs-ride="carousel">
													<div class="h-100 carousel-inner">
														<div class="carousel-item active h-100">
															<img :src="saleslist.상품매칭[saleslist.currentindex].pic_url" class="d-block h-100" alt="...">
														</div>
													</div>
												</div>
												<button type="button" class="btn btn-outline-dark" @click="nextimg(saleslist,false)">&gt;</button>
											</div>
										</div>
									</b-modal>
								</td>
								<td style="overflow:hidden;text-overflow: ellipsis;">{{saleslist.매입가}}</td>
								<td style="overflow:hidden;text-overflow: ellipsis;">{{saleslist.월수익}}</td>
								<td style="overflow:hidden;text-overflow: ellipsis;">{{saleslist.마진}}<br>({{saleslist.마진율}}%)</td>
								<td style="width:100px;overflow:hidden;text-overflow: ellipsis;">
									<input type="number" class="form-control w-100 text-center" placeholder="판매가" v-model="saleslist.판매가" @change="changeCost(saleslist)">
								</td>
								<td style="overflow:hidden;text-overflow: ellipsis;">{{saleslist.수수료}}</td>
								<td style="overflow:hidden;text-overflow: ellipsis;">{{saleslist.월판매량}}</td>
								<td style="overflow:hidden;text-overflow: ellipsis;">{{saleslist.월매출}}</td>
								<td style="overflow:hidden;text-overflow: ellipsis;">{{saleslist.리뷰수}}</td>
								<td style="overflow:hidden;text-overflow: ellipsis;">{{saleslist.리뷰평점}}</td>
								
								<td style="overflow:hidden;text-overflow: ellipsis;">
									<span v-if="!saleslist.로켓뱃지">없음</span><img v-if="saleslist.로켓뱃지" :src="saleslist.로켓뱃지" alt="image" style="max-width:100%">
								</td>
								
									
									<!-- <b-modal :id="'modal'+index" scrollable title="판매세부내역" ok-title="OK" cancel-title="Close" class="detail-modal"  style="font-size:80%">
										<div class="row">
											<div class="col-12">
												<card style="padding:10px;">										
													<div class="d-flex align-items-center" style="margin:auto;padding:0px">
														<img :src="saleslist.썸네일" class="img-fluid rounded avatar-120 mr-3" alt="image">
														<div>
														<p>판매상품명 : {{saleslist.판매상품명}}</p>
														<p>로켓구분 : {{saleslist.로켓구분}}</p>
														<p>로켓뱃지 : <span v-if="!saleslist.로켓뱃지">없음</span><img v-if="saleslist.로켓뱃지" :src="saleslist.로켓뱃지" alt="image" style="max-width:35%"></p>
														
														</div>
													</div>
												</card>
											</div>
											<div class="col-12">
												※ 최대 100줄로 표시되며 그 이상 수집하는 경우 오래된 순으로 자동 삭제됩니다.
												<div class="table-responsive">
													<table id="datatable" class="table data-table table-striped dataTable text-center" >
														<thead>
															<tr class="ligth">
																
																<th>Date</th>
																<th>로켓뱃지</th>
																<th>재고</th>
																<th>판매</th>
																<th>판매가</th>
																<th>판매매출</th>
																<th>리뷰수</th>
																<th>리뷰평점</th>
																<th>판매링크</th>
															</tr>
														</thead>
														<tbody>
															<tr v-for="(list,idx) in saleslist.판매세부내역" :key="idx">
																
																<td style="width:100px;overflow:hidden;text-overflow: ellipsis;">{{list.date}}</td>
																<td style="width:100px;overflow:hidden;text-overflow: ellipsis;">
																	<span v-if="!saleslist.로켓뱃지">없음</span><img v-if="saleslist.로켓뱃지" :src="saleslist.로켓뱃지" alt="image" style="max-width:100%">	
																</td>
																<td style="width:50px;overflow:hidden;text-overflow: ellipsis;">{{list.재고}}</td>
																<td style="width:50px;overflow:hidden;text-overflow: ellipsis;">{{list.판매}}</td>
																<td style="width:50px;overflow:hidden;text-overflow: ellipsis;">{{list.판매가}}</td>
																<td style="width:50px;overflow:hidden;text-overflow: ellipsis;">{{list.판매매출}}</td>
																<td style="width:50px;overflow:hidden;text-overflow: ellipsis;">{{list.리뷰수}}</td>
																<td style="width:50px;overflow:hidden;text-overflow: ellipsis;">{{list.리뷰평점}}</td>
																<td style="width:50px;overflow:hidden;text-overflow: ellipsis;" class="text-center"><a :href="saleslist.판매링크" target="_blank"><b-icon icon="link" scale="2" variant="primary"></b-icon></a></td>
															</tr>
															<tr>
																<td style="width:100px;overflow:hidden;text-overflow: ellipsis;"></td>
																<td style="width:100px;overflow:hidden;text-overflow: ellipsis;"></td>
																<td style="width:100px;overflow:hidden;text-overflow: ellipsis;">일평균판매량</td>
																<td style="width:100px;overflow:hidden;text-overflow: ellipsis;">{{saleslist.일평균판매량.toFixed(2)}}</td>
																<td style="width:100px;overflow:hidden;text-overflow: ellipsis;">일평균매출</td>
																<td style="width:100px;overflow:hidden;text-overflow: ellipsis;">{{saleslist.일평균매출.toFixed(0)}}</td>
																<td style="width:100px;overflow:hidden;text-overflow: ellipsis;"></td>
																<td style="width:100px;overflow:hidden;text-overflow: ellipsis;"></td>
																<td style="width:100px;overflow:hidden;text-overflow: ellipsis;"></td>
															</tr>
															<tr>
																<td style="width:100px;overflow:hidden;text-overflow: ellipsis;"></td>
																<td style="width:100px;overflow:hidden;text-overflow: ellipsis;"></td>
																<td style="width:100px;overflow:hidden;text-overflow: ellipsis;">총판매량</td>
																<td style="width:100px;overflow:hidden;text-overflow: ellipsis;">{{saleslist.판매세부내역.reduce((pv,cv) => {return pv + cv.판매},0)}}</td>
																<td style="width:100px;overflow:hidden;text-overflow: ellipsis;">총매출</td>
																<td style="width:100px;overflow:hidden;text-overflow: ellipsis;">{{saleslist.판매세부내역.reduce((pv,cv) => {return pv + cv.판매매출},0)}}</td>
																<td style="width:100px;overflow:hidden;text-overflow: ellipsis;"></td>
																<td style="width:100px;overflow:hidden;text-overflow: ellipsis;"></td>
																<td style="width:100px;overflow:hidden;text-overflow: ellipsis;"></td>
															</tr>															
														</tbody>
													</table>
												</div>   
												
											</div>   
										</div>  
                                    </b-modal> -->

							</tr>
						</template>
					</tableList>
				</div>
				<div class="d-flex justify-content-between">
					<div>
						<button type="button" class="btn btn-outline-primary mr-2 mb-4" @click="selectline">현재페이지 전체선택</button>
						<button type="button" class="btn btn-primary mr-2 mb-4" @click="saveitem">저장</button>
						<button type="button" class="btn btn-success mr-2 mb-4" @click="downloadsales">다운로드</button>
					</div>
					<button type="button" class="btn btn-secondary mr-2 mb-4" @click="deleteitem">삭제</button>
				</div>

				

					<!-- <button type="button" class="btn btn-success mr-2 mb-4" @click="salesdownload">상세다운로드</button> -->
					
				
			

			</div>
		</div>
		<!-- Page end  -->
	</div>
</template>
<script>
import axios from 'axios';
import { core } from '../../../config/pluginInit';
// import { splice } from 'core-js/internals/enum-bug-keys';
import * as XLSX from 'xlsx';
// import { json } from 'body-parser';
export default {
	name:'salesstatus',
	data(){
		return{
			purchaseRatio:300,
			charge: 35,

			myData : [1000,500,250,125,62,31,15,7,3,1,1,1,1,1,0],
			salesarr:[],
			importarr:[],
			stockarr:[],
			data:[],
			checkalllists:false,

			salescnt:0,
			importcnt:0,
			salesamount:0,
			supplyamount:0,
			noreview:0,
			productqty:0,
			nostock:0,
			// increview:0,
			// decreview:0,

			loadingtext:'데이터 불러오는 중 ...',


			title:"장바구니",
			desc2:"상품등록 및 구매진행이 진행되지 않는 경우 14일 후에 자동으로 삭제됩니다.",
			desc1:"장바구니의 상품은 기본적으로 다른 사용자에게 노출되지 않으며",
			desc3:"장바구니 상품은 100개의 한도가 정해져 있습니다.",
			link:{ name: 'people.addusers'},
			linktext:"판매현황 수집",

			SKUID:'',
			showspinner: false,
			startdate: this.getYesterday(),
			enddate: this.getTodaywith(),
			saleslists: [],
			saleslistsinit: [],
			showlist: false,

			maxrows : 200,
			minuslimit : -2,
			applyminuslimit : false,

			UserListS:[
				{
					name:'Cliff Hanger',
					email:'cliff@gmail.com',
					company:'Product Manager', 
					group:'Cliff',
					status:'Active',
					store:'1'
				},
				{
					name:'Terry Aki',
					email:'terry@gmail.com',
					company:'Stock CEO', 
					group:'Terry',
					status:'Active',
					store:'2'
				}
			],

		}
	},
	beforeDestroy() {
		window.removeEventListener('message', this.messageEventHandler);
	},
    mounted() {
		this.messageEventHandler = async(event) => {
			if(event.data.res && event.data.res === 'tracking'){
                if(!event.data.msg.includes('수집완료')){
					this.loadingtext = '데이터 불러오는 중 ...';
					alert(event.data.msg)
					this.showspinner = false;
					this.showlist = true;					
				} else {
					this.search()
				}
            }
            if(event.data.res && event.data.res === 'trackingratio'){
                this.loadingtext = '데이터 불러오는 중 ... ' + parseInt(event.data.ratio*100) + '%';
            }	
		}
		window.addEventListener('message', this.messageEventHandler);
        this.$nextTick(function () {
            this.search()      
        })
    },
	methods: {
		selectline(){
			var pageInfo = core.checkpage();
			for(var i=0;i<this.saleslists.length;i++){
				if(i >= pageInfo.start && i < pageInfo.end){
					this.saleslists[i].checked = true
				}
			}
		},
		showts(){
            this.showlist=false;
            setTimeout(() => {
                this.saleslists = this.saleslistsinit.filter(e => e.type == 'trendSetter');
                this.showlist = true;
            }, 1);
        },
		showtf(){
            this.showlist=false;
            setTimeout(() => {
                this.saleslists = this.saleslistsinit.filter(e => e.type == 'trendFollower');
                this.showlist = true;
            }, 1);
        },
		previmg(saleslist,bool){
			if(bool){
				this.showlist = false;
			}
			
			if(saleslist.currentindex == 0){
				saleslist.currentindex = saleslist.상품매칭.length - 1;
				return
			}
			saleslist.currentindex--

			if(bool){
				this.applyCurrentObj(saleslist);
				setTimeout(()=>{
					this.showlist = true;
				},1)
			}
		},
		nextimg(saleslist,bool){
			if(bool){
				this.showlist = false;
			}
			if(saleslist.currentindex == saleslist.상품매칭.length - 1){
				saleslist.currentindex = 0;
				return
			}
			saleslist.currentindex++
			if(bool){
				this.applyCurrentObj(saleslist);
				setTimeout(()=>{
					this.showlist = true;
				},1)
			}
		},
		applyCurrentObj(saleslist){
			var e = saleslist;
			saleslist.월수익 = (e.판매가*0.9 - parseInt(e.상품매칭[e.currentindex].price*this.purchaseRatio))*parseInt(e.월매출/e.판매가)
			saleslist.마진 = e.판매가*0.9 - parseInt(e.상품매칭[e.currentindex].price*this.purchaseRatio)
			saleslist.마진율 = parseInt((e.판매가*0.9 - parseInt(e.상품매칭[e.currentindex].price*this.purchaseRatio))/e.판매가*100)
			saleslist.매입가 = parseInt(e.상품매칭[e.currentindex].price*this.purchaseRatio)
		},
		openWebsite(url) {
			window.open(url, "_blank");
		},
		linktopurchase(purchase){
			window.open(purchase.detail_url,"_blank");
		},
		applycorrect(){
			if(this.data.length > 0){
				this.showspinner = true;
				this.showlist = false;				
				this.searchresult()
			} else {
				alert('장바구니에 찜한 상품이 없습니다.')
				return
			}
		},
		checkalllist(){
			if(this.checkalllists){
				this.saleslists.forEach(e => {
					e.checked = true
				})
			} else {
				this.saleslists.forEach(e => {
					e.checked = false
				})
			}
		},
		saveitem(){
			var arr = this.saleslists
			if(confirm('현재 상태를 저장하시겠습니까?')){
				axios.post('/api/nomad/updatesaveitem',arr)
				.then(result => {
					if(result.data.res == 'OK'){
						alert('저장완료')
						console.log(result)
					} else {
						alert(result.data.alert)
						console.log(result)
					}
				})
			}
		},
		deleteitem(){
			if(this.saleslists.filter(e => e.checked).length == 0){
				alert('삭제할 상품을 선택해 주세요')
			} else {
				var arr = this.saleslists.filter(e => e.checked)
				if(arr.length == this.saleslists.length){
					if(!confirm('전체 내역을 삭제하시겠습니까?')){
						return
					}
				} else {
					if(!confirm(arr.length + '개의 상품을 삭제하시겠습니까?')){
						return	
					}
				}
				axios.post('/api/nomad/deletesaveitem',arr)
				.then(result => {
					if(result.data.res == 'OK'){
						this.search()
					} else {
						alert('삭제실패')
						console.log(result)
					}
				})
			}
		},
		collecttr(){
			window.postMessage({greeting: "collecttracking", companyid:this.$store.state.user.companyid},"*",)
			this.showspinner = true;
			this.showlist = false;
		},
		modalID(index) {
            // console.log(index);
            return 'modal' + index
        },
		modalIDca(index) {
            // console.log(index);
            return 'modalca' + index
        },
		salesdownload(){
			if(this.saleslists.length == 0){
				alert('다운로드할 내역이 없습니다.')
				return
			}
			const workBook = XLSX.utils.book_new()
			var obj = {};
			var arr = [];
			this.saleslists.forEach(e => {
				arr = [];
				e.판매세부내역.forEach(ele => {
					obj = {
						상품명 : e.판매상품명,
						Date : ele.date,
						재고 : ele.재고,
						판매 : ele.판매,
						판매가 : ele.판매가,
						판매매출 : ele.판매매출,
						리뷰수 : ele.리뷰수,
						리뷰평점 : ele.리뷰평점
					}
					arr.push(obj)
				})
				var workSheet = XLSX.utils.json_to_sheet(arr)
				XLSX.utils.book_append_sheet(workBook, workSheet, e.vendorItemId.toString())
			})

			
			XLSX.writeFile(workBook, '일자별판매현황.xlsx')
		},
		search(){
			this.showspinner = true;
			this.showlist = false;
			axios.post('/api/nomad/getsaveitem',{companyid:this.$store.state.user.companyid})
			.then(result => {
                if(result.data.length > 0){
					console.log(result.data)
					this.searchresult(result.data)
                } else {
                    alert('추가된 모니터링 상품이 없습니다.')
                    return
                }
			})
		},
		datetotimestamp(a){
			var date = new Date(a);
			return date.getTime();
		},
        searchresult(data){
            this.saleslists = [];
            this.saleslistsinit = [];
            var obj = {}
            var arr = [];
            data.forEach(e => {
				obj = {
					_id:e._id,
					checked : false,
					썸네일 : e.썸네일,
					판매상품명 : e.판매상품명,
					productId : e.productId,
					판매가 : e.판매가,
					타겟키워드 : e.타겟키워드,
					리뷰수 : e.리뷰수,
					리뷰평점 : e.리뷰평점,
					type:e.type,
					월수익 : e.월수익,
					마진 : e.마진,
					구매가 : e.구매가,
					마진율 : e.마진율,
					매입가 : e.매입가,
					매입가율 : e.매입가율,
					수수료 : e.수수료,
					월판매량 : e.월판매량,
					월매출 : e.월매출,
					상품매칭 : e.상품매칭,
					판매링크 : e.판매링크,
					currentindex : e.currentindex,
					로켓뱃지 : e.로켓뱃지,
				}
				arr.push(obj)
            })
            arr.forEach(e => {
                this.saleslists.push(this.deepClone(e))
                this.saleslistsinit.push(this.deepClone(e))
            })

            if(this.saleslists.length == data.length){
				setTimeout(() => {
					this.showlist = true;
					this.showspinner = false;
				}, 10);
            }
			console.log(this.saleslists)
            
        },
		timestamptodate(timestamp){
			var d = new Date(timestamp + (1000*60*60*9));
			return d.toISOString().replace('T',' ').slice(0,19)
		},
		searchresult2(){
			this.saleslists = [];
			this.salesarr = [];
			this.saleslistsinit = [];
			var obj = {};
			var d = {};
			// var 이전재고 = '';
			// var t = {};
			// var su = {};
			// var 입고수량 = 0;
			var sales = [];
			this.$store.state.sales.forEach(e => {
				sales.push(this.deepClone(e))
			})
			var supply = [];
			this.$store.state.supply.forEach(e => {
				supply.push(this.deepClone(e))
			})
			var productdb = this.$store.state.productdb.filter(e => !e.등록이미지?.includes('vendor_inventory'));
			if(this.$store.state.sales.length > 0){
				console.log(this.$store.state.sales)
				console.log(this.$store.state.supply)
				var dates = [];
				sales.forEach(e => {
					if(!dates.find(ele => ele.date == e.date)){
						dates.push({date : e.date})
					}
				})
				supply.forEach(e => {
					if(!dates.find(ele => ele.date == e.importdate) && dates[0].date < e.importdate){
						dates.push({date : e.importdate})
					}
				})
				// console.log(dates)
				dates.sort(function(a,b){
					if (a.date > b.date){
						return 1;
					}
					if (a.date < b.date){
						return -1;
					}
					return 0;
				})
				productdb.sort(function(a,b){
					if (a["SKU ID"] > b["SKU ID"]){
						return 1;
					}
					if (a["SKU ID"] < b["SKU ID"]){
						return -1;
					}
					return 0;
				})
				sales.forEach(e => {
					e.판매세부내역.sort(function(a,b){
						if (a.sk > b.sk){
							return 1;
						}
						if (a.sk < b.sk){
							return -1;
						}
						return 0;
					})
				})
				supply.forEach(e => {
					e.importlist.sort(function(a,b){
						if (a.sku번호 > b.sku번호){
							return 1;
						}
						if (a.sku번호 < b.sku번호){
							return -1;
						}
						return 0;
					})
				})

				

				for(var i=0;i<productdb.length;i++){
					var pred = {
						재고 : 0,
						입고 : 0,
						리뷰수 : 0,
						리뷰평점 : 0,
						판매가 : 0,
					}
					obj = {
						썸네일 : productdb[i].썸네일,
						판매상품명 : productdb[i].판매상품명,
						SKUID : productdb[i]["SKU ID"],
						바코드 : productdb[i].바코드,
						발주가능상태 : productdb[i].발주가능상태,
						재고 : 0,
						판매 : 0,
						입고 : 0,
						판매가 : 0,
						판매매출 : 0,
						공급매출 : 0,
						리뷰수 : 0,
						리뷰평점 : 0,
						판매링크 : productdb[i].판매링크,
						판매세부내역 : []
					}
					var obsales = {
						판매상품명 : productdb[i].판매상품명,
						SKUID : productdb[i]["SKU ID"],
						바코드 : productdb[i].바코드
					}
					var obstock = {
						판매상품명 : productdb[i].판매상품명,
						SKUID : productdb[i]["SKU ID"],
						바코드 : productdb[i].바코드
					}
					var obimport = {
						판매상품명 : productdb[i].판매상품명,
						SKUID : productdb[i]["SKU ID"],
						바코드 : productdb[i].바코드
					}										
					dates.forEach((e,k) => {

						d = {
							date : e.date,
							발주가능상태 : productdb[i].발주가능상태,
							재고 : 0,
							판매 : 0,
							입고 : 0,
							판매가 : 0,
							판매매출 : 0,
							공급매출 : 0,
							리뷰수 : 0,
							리뷰평점 : 0,
							판매링크 : productdb[i].판매링크,
							재고확인시간 : 0,
						}
						// console.log(e)
						// console.log(sales.findIndex(ele => ele.date == e.date))
						if(sales.findIndex(ele => ele.date == e.date) >= 0){
							var j = sales.findIndex(ele => ele.date == e.date)
							// console.log(j)
							for(var n=0;n<sales[j].판매세부내역.length;n++){
								
								if(parseInt(sales[j].판매세부내역[n].sk) == parseInt(productdb[i]["SKU ID"])){
									if(productdb[i]["SKU ID"] === 17064982 || productdb[i]["SKU ID"] ===  27573440){
										console.log(e)
										console.log(sales[j].판매세부내역[n])
									}
									d.재고 = sales[j].판매세부내역[n].s === 0 || sales[j].판매세부내역[n].s > 0 ? parseInt(sales[j].판매세부내역[n].s) : sales[j].판매세부내역[n].s < 0 ? 0 : pred.재고;
									d.리뷰수 = sales[j].판매세부내역[n].rq === 0 || sales[j].판매세부내역[n].rq ? parseInt(sales[j].판매세부내역[n].rq) : pred.리뷰수;
									d.리뷰평점 = sales[j].판매세부내역[n].rp === 0 || sales[j].판매세부내역[n].rp ? parseFloat(sales[j].판매세부내역[n].rp) : pred.리뷰평점;
									d.판매가 = sales[j].판매세부내역[n].p === 0 || sales[j].판매세부내역[n].p ? parseFloat(sales[j].판매세부내역[n].p) : pred.판매가;
									d.재고확인시간 = sales[j].time ? sales[j].time : 0;
									sales[j].판매세부내역.splice(n,1)
									// console.log(sales[j].판매세부내역.length)
									break;
								}
								if(parseInt(sales[j].판매세부내역[n].sk) > parseInt(productdb[i]["SKU ID"])){
									d.재고 = pred.재고;
									d.리뷰수 = pred.리뷰수;
									d.리뷰평점 = pred.리뷰평점;
									d.판매가 = pred.판매가;
									break;
								}
							}
						} else {
							d.재고 = pred.재고;
							d.리뷰수 = pred.리뷰수;
							d.리뷰평점 = pred.리뷰평점;
							d.판매가 = pred.판매가;
						}
						// if(productdb[i]["SKU ID"] === 18462723){
						// 	console.log(d.재고)
						// }
						if(supply.findIndex(ele => ele.importdate == e.date) >= 0){
							j = supply.findIndex(ele => ele.importdate == e.date)
							for(n=0;n<supply[j].importlist.length;n++){
								// console.log(supply[j].importlist[n])
								// console.log(supply[j].importlist[n].sku번호)
								if(parseInt(supply[j].importlist[n].sku번호) == parseInt(productdb[i]["SKU ID"])){
									// if(productdb[i]["SKU ID"] === 17064982 || productdb[i]["SKU ID"] ===  27573440){
									// 	console.log(e)
									// 	console.log(supply[j].importlist[n])
									// 	console.log(d.재고)
									// 	console.log(d.재고확인시간)
									// 	console.log(this.datetotimestamp(supply[j].importlist[n]['입고/반출일자']))
									// 	console.log(d.재고확인시간 < this.datetotimestamp(supply[j].importlist[n]['입고/반출일자']))
									// }
									d.입고 += parseInt(supply[j].importlist[n].수량);
									if(d.재고확인시간 <= this.datetotimestamp(supply[j].importlist[n]['입고/반출일자'])){
										d.재고 += parseInt(supply[j].importlist[n].수량);
									}
									// if(productdb[i]["SKU ID"] === 18462723){
									// 	console.log(supply[j].importlist[n])
									// 	console.log(d.재고)
									// }
								}
								if(parseInt(supply[j].importlist[n].sku번호) > parseInt(productdb[i]["SKU ID"])){
									// d.입고 = pred.입고;
									// d.재고 = pred.재고;
									break;
								}
							}
						}
						if(k == 0){
							d.판매 = 0;
						} else {
							d.판매 = pred.재고 - d.재고 + d.입고;
						}
						d.판매매출 = d.판매가 * d.판매;
						d.공급매출 = productdb[i].매입가 ? productdb[i].매입가 * d.판매 : productdb[i].현재매입가 ? productdb[i].현재매입가 * d.판매 : 0;
						pred = d;
						obj.판매세부내역.push(d)

						obj.재고 = d.재고;
						obj.판매 += d.판매;
						obj.입고 += d.입고;
						obj.판매매출 += d.판매매출;
						obj.공급매출 += d.공급매출;
						obj.판매가 = d.판매가;
						obj.리뷰수 = d.리뷰수;
						obj.리뷰평점 = d.리뷰평점;

						obsales[e.date] = d.판매;
						obimport[e.date] = d.입고;
						obstock[e.date] = d.재고;
					})
					this.salesarr.push(obsales);
					this.stockarr.push(obstock);
					this.importarr.push(obimport);

					this.saleslists.push(this.deepClone(obj))
					this.saleslistsinit.push(this.deepClone(obj))
				}
				
				
				this.salescnt = this.saleslists.reduce((pv,rv) => { return pv + rv.판매},0)
				this.importcnt = this.saleslists.reduce((pv,rv) => { return pv + rv.입고},0)
				this.salesamount = this.saleslists.reduce((pv,rv) => { return pv + rv.판매매출},0)
				this.supplyamount = this.saleslists.reduce((pv,rv) => { return pv + rv.공급매출},0)
				this.noreview = this.saleslists.filter(e => e.리뷰수 == 0).length;
				this.productqty = this.saleslists.length;
				this.nostock = this.saleslists.filter(e => e.재고 == 0).length;

				if(this.saleslists.length == 0){
					alert("판매현황이 없습니다.")
					this.showspinner = false;
					return
				}
				setTimeout(() => {
					this.showlist = true;
					this.showspinner = false;
				}, 1);
			} else {
				alert("판매현황이 없습니다.")
				this.showspinner = false;
				return
			}			
			console.log(this.salesarr)
		},
		
		deepClone(obj) {
			if (obj === null || typeof obj !== "object") {
				return obj
			}

			const result = Array.isArray(obj) ? [] : {}

			for (let key of Object.keys(obj)) {
				result[key] = this.deepClone(obj[key])
			}

			return result
		}, 		
		todate(date){
			var today = new Date(date); 
			today.setHours(today.getHours() + 9); 
			return today.toISOString().replace('T', ' ').substring(0, 19);
		},
        timestamp(date){
            //inputdate = "yyyy-mm-dd"
            return new Date(date).getTime()
        },		
		getTodaywith(){
			var date = new Date();
			var year = date.getFullYear();
			var month = ("0" + (1 + date.getMonth())).slice(-2);
			var day = ("0" + date.getDate()).slice(-2);

			return year + "-" + month + "-" + day;
		},
		getDatewith(d){
			var date = new Date(d);
			var year = date.getFullYear();
			var month = ("0" + (1 + date.getMonth())).slice(-2);
			var day = ("0" + date.getDate()).slice(-2);

			return year + "-" + month + "-" + day;
		},
		getYesterday(){
			return this.getDatewith(this.datetotimestamp(this.getTodaywith()) - 1000*60*60*24)
		},
		downloadsales() {
			if(this.saleslists.length == 0){
				alert('다운로드할 내역이 없습니다.')
				return
			}
			function getToday(){
				var date = new Date();
				var year = date.getFullYear();
				var month = ("0" + (1 + date.getMonth())).slice(-2);
				var day = ("0" + date.getDate()).slice(-2);

				return year + month + day;
			}
			var lists = this.saleslists.filter(e => e.checked);
			if(lists.length == 0){
				lists = this.saleslists
			}
			var arr = [];
			var obj = {};
			lists.forEach(e => {
				if(e.로켓뱃지 == 'https://image7.coupangcdn.com/image/coupang/rds/logo/iphone_2x/logoRocketMerchantLargeV3R3@2x.png'){
					var 로켓뱃지 = '판매자로켓'		
				}
				if(e.로켓뱃지 == 'https://image6.coupangcdn.com/image/cmg/icon/ios/logo_rocket_large@3x.png'){
					로켓뱃지 = '로켓배송'		
				}
				if(e.로켓뱃지 == ''){
					로켓뱃지 = '마켓플레이스'		
				}
				obj = {
					썸네일 : e.썸네일,
					판매상품명 : e.판매상품명,
					상품번호 : e.productId,
					판매가 : e.판매가,
					타겟키워드 : e.타겟키워드,
					리뷰수 : e.리뷰수,
					리뷰평점 : e.리뷰평점,
					소싱타입 : e.type,
					월수익 : e.월수익,
					마진 : e.마진,
					마진율 : e.마진율,
					ROI : e.마진/e.매입가,
					매입가 : e.매입가,
					수수료 : e.수수료,
					월판매량 : e.월판매량,
					월매출 : e.월매출,
					로켓뱃지 : 로켓뱃지,
					판매상품링크 : e.판매링크,
					구매상품링크 : e.상품매칭[e.currentindex].detail_url,
					'1.타겟키워드/상품간 매칭성(TS)':'',
					'2.지재권 문제':'',
					'3.마진율/ROI 만족여부':'',
					'4.타겟키워드 상품대비 차별성':'',
					'5.쿠팡 연관검색어에 동일상품 여부':'',
					'6.KC인증/전파인증/식검':'',
					'7.최근 1개월 가리뷰(TF)':'',
					'8.리뷰상 상품 문제점':'',
					'9.타겟키워드 재설정':'',
				}
				arr.push(obj)
			})


			const workBook = XLSX.utils.book_new()
			const workSheet1 = XLSX.utils.json_to_sheet(arr)
			XLSX.utils.book_append_sheet(workBook, workSheet1, '경쟁사판매현황')
			XLSX.writeFile(workBook, '경쟁사판매현황' + '_' + getToday() + '.xlsx')
		}
	}

}
</script>

<style scoped>
.my-class .dropdown-menu {
	max-height: 300px;
	width:800px;
	overflow-y: auto;
}

</style>